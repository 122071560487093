<template>
    <v-container
            fill-height
            fluid
            grid-list-xl
    >
        <v-layout
                justify-center
                wrap
        >
            <template v-if="loaded">
                <data-table ref="datatable"
                            :key="'VoucherCodes'"
                            :api-end-point="apiurl"
                            :search="search"
                            :sort-order="sortOrder"
                            :is-custom-filter="false"
                            :table-name="'Vouchers'"
                            :user_id="user_id"
                            :user_type="user_type"
                            :isDetail="false"
                            :is-edit="true"
                            :isExport="true"
                            :isId="true"
                            :pre-selected-filters="filters.selectedFilters"
                >
                    <template slot="filters">
                        <filters :filters="filters.voucherCodePlan"></filters>
                        <filters :filters="filters.voucherCodeOrganisation"></filters>
                        <filters :filters="filters.voucherCodeType"></filters>
                    </template>
                </data-table>
            </template>

            <create-voucher v-if="showVoucherModal"
                            :visible="showVoucherModal"
                            :actionType="actionType"
                            :value="selectedVoucher"
                            v-on:hide="showVoucherModal = false"
            ></create-voucher>

        </v-layout>

    </v-container>
</template>

<script>

    import DataTable from "../../components/common/dataTable/DataTable";
    import Filters from "../../components/common/dataTable/filters/Filters";
    import CreateVoucher from "../../components/main/orgConsole/manageVouchers/CreateVoucher";

    export default {
        name: "ManageVouchers",
        components: {
            DataTable,
            Filters,
            CreateVoucher
        },
        props: {
            user_id: {
                user_id: [Number, String]
            },
            user_type: {
                type: String
            },
        },
        data() {
            return {
                apiurl: 'partner_voucher_codes/'+this.$route.params.id ,
                search: {
                    placeholder: 'Search codes, membership numbers',
                    searchAble: true
                },
                sortOrder: [
                    {
                        field: 'formatted_plan_id',
                        sortField: 'plan_id',
                        direction: 'asc'
                    }
                ],
                filters: [],
                selected_filters: [],
                selectedVoucher: [],
                loaded: false,
                showVoucherModal: false,
                actionType: "",
            }
        },
        mounted() {
        },
        created() {
            this.fetchFilters();
        },
        methods: {
            fetchFilters(){
                this.$http.get(this.url+'filters/voucher_codes/'+this.$route.params.id).then((res) => {
                    if(res.data) {
                        this.filters = res.data;
                        this.loaded = true;
                    } else {
                        alert('there is some problem');
                    }
                });
            },
            hideVoucherModal() {
                this.showVoucherModal = false;
            },
        },
        events: {
            'edit-record': function (data) {
                this.selectedVoucher = data;
                this.actionType = "edit";
                this.showVoucherModal = true;
            },
        },
    }
</script>

<style scoped>

</style>